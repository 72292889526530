<template>
  <div>
    <ejs-dialog
        allowDragging="true"
        header="비고사항 편집"
        ref="dialog"
        showCloseIcon="true"
        width="760"
        :position="{ x: 'center', y: 'center' }"
        :isModal="true"
        :visible="false"
        @close="onDialogClose"
        @overlayClick="onDialogOverlayClick"
    >
      <div class="window calendarWeather">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title">
                                비고
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                          ref="sendMessage"
                                          v-model="remarks"
                                          :rows="6"
                                          maxlength="-1"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">
                                작성자/일시
                              </div>
                              <div class="content">
                                <ul class="row" v-if="updateDt">
                                  <li class="item">
                                    {{ updateName }} /
                                    {{ updateDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" @click.native="onSubmitClick">
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onCloseClick">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .section-body > span {margin: 0;}
.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {border-width: 0 !important;}
</style>

<script>
import InputTextarea from '@/components/common/text/InputTextarea';
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'DailyCloseAddRemarksPopup',
  components: {
    InputTextarea,
    ErpButton,
  },
  data() {
    return {
      remarks: null,
      updateName: null,
      updateDt: null,
    };
  },
  methods: {
    show(remarks, updateName = null, updateDt = null) {
      this.remarks = remarks;
      this.updateName = updateName;
      this.updateDt = updateDt;
      this.$refs['dialog'].show();
    },
    hide() {
      this.$refs['dialog'].hide();
    },
    onDialogClose() {
      this.$emit('close');
    },
    onDialogOverlayClick() {
      this.hide();
    },
    onSubmitClick() {
      this.$emit('submit', this.remarks);
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },
  },
};
</script>
