<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field memberGrade">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref="searchBsnDatePicker"
                    format="YYYY-MM-DD"
                    type="lookup-condition"
                    v-model="dailyReportSearchOptions.bsnDate"
                />
              </li>
              <li class="item text">
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      new Date(dailyReportSearchOptions.bsnDate)
                    ),
                  }"
              >{{
                  getDayOfWeekCaption(
                      new Date(dailyReportSearchOptions.bsnDate),
                  )
                }}</span
              >)(<span
                  :style="{
                    color: getWeekdayWeekendCaptionColor(
                      new Date(dailyReportSearchOptions.bsnDate)
                    ),
                  }"
              >{{
                  getWeekdayWeekendCaption(
                      new Date(dailyReportSearchOptions.bsnDate),
                  )
                }}</span
              >)
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="prev">
                    <erp-button
                        button-div="GET"
                        v-on:click.native="onBsnDatePreChange"
                    />
                  </li>
                  <li class="next">
                    <erp-button
                        button-div="GET"
                        v-on:click.native="onBsnDateNextChange"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field" v-if="false">
            <div class="title">스타일</div>
            <ul class="content">
              <li class="item date">
                <ejs-dropdownlist
                    ref="detailBsnDiv"
                    v-model="dailyReportSearchOptions.reportStyle"
                    :dataSource="reportStyles"
                    :allowFiltering="false"
                    :fields="reportStylesOptions"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field" v-if="false">
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="true"
                          v-model="dailyReportSearchOptions.amtFlag"
                          name="amtFlag"
                      />
                      <i/>
                      <div class="label">총매출</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="false"
                          v-model="dailyReportSearchOptions.amtFlag"
                          name="amtFlag"
                      />
                      <i/>
                      <div class="label">순매출</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field" v-if="isAStyle || isBStyle">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          id="cmpnPrintFlag"
                          v-model="dailyReportSearchOptions.showWeatherFlag"
                          value="true"
                      />
                      <i></i>
                      <div class="label">날씨 출력</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li v-if="false">
            <erp-button
                button-div="GET"
                :ignore="isPopupOpened"
                :is-shortcut-button="true"
                @click.native="onAddRemarksClicked"
            >
              비고사항 편집
            </erp-button>
          </li>
          <li class="print">
            <erp-button
                button-div="PRINT"
                :ignore="isPopupOpened"
                @click.native="onPrintClicked"
                :is-shortcut-button="true"
            >
              인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="false"/>
    </div>
    <daily-close-add-remarks-popup
        v-if="isDailyCloseAddRemarksPopupOpen"
        ref="dailyCloseAddRemarksPopup"
        @close="onDailyCloseAddRemarksPopupClose"
        @submit="onDailyCloseAddRemarksPopupSubmit"
    />
  </div>
</template>

<style scoped>
body .content-wrapper .content-body form {margin-top: -21px;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import {getCurrentBizCode} from '@/utils/pageKeyUtil';
import {
  getFormattedDate,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
  getDateOfNext,
  getDateOfPrevious,
} from '@/utils/date';
import {
  getDailyReport,
  putDailyReportRemarks,
} from '@/api/dailyReport';
import InputDate from '@/components/common/datetime/InputDate';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import ReportView from '@/components/common/report/ReportView';
import {mapGetters} from 'vuex';
import DailyCloseAddRemarksPopup from '@/views/daily-close/popup/DailyCloseAddRemarksPopup';
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import moment from 'moment';
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetComName,
} from '@/utils/commonCodes';
import {getBizInfo} from '@/api/bizCodeManagement';

export default {
  name: 'DailyReport',
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputDate,
    ReportView,
    ErpButton,
    DailyCloseAddRemarksPopup,
  },
  async created() {
    this.dailyReportSearchOptions.bsnDate = getFormattedDate(new Date());
    await this.onViewButtonClicked();
  },
  data() {
    return {
      closeDiv: null,
      dailyReportSearchOptions: {
        bsnDate: '',
        bsnCode: false,
        reportStyle: 'C',
        amtFlag: true,
        showWeatherFlag: false,
      },
      reportStyles: [
        {comName: 'A 타입', comCode: 'A'},
        {comName: 'B 타입', comCode: 'B'},
        {comName: 'C 타입', comCode: 'C'},
      ],
      reportStylesOptions: {text: 'comName', value: 'comCode'},
      dailyReportData: {
        monthlyBsnDateInfo: {},
        todayFeeInfo: {},
        closeCard: {},
      },
      remarks: null,
      updateName: null,
      updateDt: null,
      isDailyCloseAddRemarksPopupOpen: false,
    };
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return (
          this.isDailyCloseAddRemarksPopupOpen
      );
    },
    isAStyle() {
      return this.dailyReportSearchOptions.reportStyle === 'A';
    },
    isBStyle() {
      return this.dailyReportSearchOptions.reportStyle === 'B';
    },
    isCStyle() {
      return this.dailyReportSearchOptions.reportStyle === 'C';
    },
  },
  methods: {
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    getWeekdayWeekendCaption,
    getWeekdayWeekendCaptionColor,
    onBsnDateNextChange() {
      this.dailyReportSearchOptions.bsnDate = getDateOfNext(
          this.dailyReportSearchOptions.bsnDate,
      );
    },
    onBsnDatePreChange() {
      this.dailyReportSearchOptions.bsnDate = getDateOfPrevious(
          this.dailyReportSearchOptions.bsnDate,
      );
    },
    async onViewButtonClicked() {
      if (!this.dailyReportSearchOptions.bsnDate) {
        return this.errorToast(
            this.$t('main.validationMessage.requiredMessage', ['영업일자']),
        );
      }

      const { data } = await GolfErpAPI.getCloseDiv({
        bsnDate: this.dailyReportSearchOptions.bsnDate,
        storeCode: 'FRONT',
      });

      this.closeDiv = data;

      if (this.closeDiv !== 'Y') {
        const bsnDate = moment(this.dailyReportSearchOptions.bsnDate).format(
            'YYYYMMDD',
        );
        const requestList = [
          'SP_CLOSE_PART_DIV',
          'SP_CLOSE_SALES_STORE',
          'SP_CLOSE_ACMTL_BASIC',
          'SP_CLOSE_ACMTL_SALES',
          'SP_CLOSE_CARD',
          'SP_CLOSE_PAYMT',
          'SP_CLOSE_SALES_FEE',
        ];
        const inParams = [bsnDate];

        let request = [];
        requestList.map((item) => request.push({name: item, inParams}));

        await GolfErpAPI.putStoredProcedureExecute({
          storedProcedureInfos: request,
        });
      }

      const response = await getDailyReport(this.dailyReportSearchOptions.bsnDate,
          this.dailyReportSearchOptions.amtFlag);
      this.dailyReportData = response.value;
      this.dailyReportData.bsnDate = this.dailyReportSearchOptions.bsnDate;

      const bsnDate = new Date(this.dailyReportSearchOptions.bsnDate);

      this.dailyReportData.bsnDateName =
          bsnDate.getFullYear() +
          '년 ' +
          (bsnDate.getMonth() + 1) +
          '월 ' +
          bsnDate.getDate() +
          '일';
      this.dailyReportData.bsnDateMonth = bsnDate.getMonth() + 1;

      this.remarks = this.dailyReportData.remarks;
      this.updateName = this.dailyReportData.updateName;
      this.updateDt = this.dailyReportData.updateDt;

      // 선수금 선불카드 기명 무기명 더하기
      const condition =  ['payDeposit', 'payPrecard', 'payGift','payGroupGift'];
      this.dailyReportData.closeAcmtlBasicList.forEach((data)=> {
        // eslint-disable-next-line no-unused-vars
        let reducePay = 0;
        for(let i of Object.keys(data)){
          if(condition.includes(i)) {
            reducePay += data[i];
          }
        }
        data.reducePay = reducePay;
      });
      await this.postReport(this.dailyReportData);
    },
    async onAddRemarksClicked() {
      this.isDailyCloseAddRemarksPopupOpen = true;
      await this.$nextTick();
      this.$refs.dailyCloseAddRemarksPopup.show(this.remarks, this.updateName, this.updateDt);
    },
    onPrintClicked() {
      this.$refs.reportViewComponent.printReport();
    },
    onDailyCloseAddRemarksPopupClose() {
      this.isDailyCloseAddRemarksPopupOpen = false;
    },
    async onDailyCloseAddRemarksPopupSubmit(remarks) {
      this.onDailyCloseAddRemarksPopupClose();
      try {
        await putDailyReportRemarks(this.dailyReportSearchOptions.bsnDate, remarks);
        this.infoToast(this.$t('main.popupMessage.saved'));
        await this.onViewButtonClicked();
      } catch (e) {
        return this.errorToast(this.$t('main.popupMessage.serverError'));
      }
    },
    async postReport(data) {
      const bizCode = getCurrentBizCode();
      const {
        value: {tsBizMstList},
      } = await getBizInfo('bizCode', bizCode);
      const bizInfo = tsBizMstList.find(item => item.bizCode === bizCode);

      let closeAcmtlSalesUnitList = [];
      let storeDailyCardReportList = [];
      let tempData = [];

      if (this.isAStyle || this.isBStyle) {
        const feeSalesUnitPriceBlock = {};
        const foodSalesUnitPriceBlock = {};
        const shopSalesUnitPriceBlock = {};
        feeSalesUnitPriceBlock['isGreenFee'] = 'Y';
        feeSalesUnitPriceBlock['divName'] = this.isAStyle
            ? '객단가\n(입장료+카트료+대여료)'
            : '객 단 가\n(입장료+카트+대여)';
        feeSalesUnitPriceBlock[`unitPriceDD`] = data.closeNetAcmtlSalesList
            .find(item => item.acmtlSumDiv === 'DD')?.feeRentCartSalesUnitPrice;
        feeSalesUnitPriceBlock[`unitPriceMM`] = data.closeNetAcmtlSalesList
            .find(item => item.acmtlSumDiv === 'MM')?.feeRentCartSalesUnitPrice;
        feeSalesUnitPriceBlock[`unitPriceYY`] = data.closeNetAcmtlSalesList
            .find(item => item.acmtlSumDiv === 'YY')?.feeRentCartSalesUnitPrice;
        foodSalesUnitPriceBlock['divName'] = '식음 객단가';
        foodSalesUnitPriceBlock[`unitPriceDD`] = data.closeNetAcmtlSalesList
            .find(item => item.acmtlSumDiv === 'DD')?.foodSalesUnitPrice;
        foodSalesUnitPriceBlock[`unitPriceMM`] = data.closeNetAcmtlSalesList
            .find(item => item.acmtlSumDiv === 'MM')?.foodSalesUnitPrice;
        foodSalesUnitPriceBlock[`unitPriceYY`] = data.closeNetAcmtlSalesList
            .find(item => item.acmtlSumDiv === 'YY')?.foodSalesUnitPrice;
        shopSalesUnitPriceBlock['divName'] = '프로샵 객단가';
        shopSalesUnitPriceBlock[`unitPriceDD`] = data.closeNetAcmtlSalesList
            .find(item => item.acmtlSumDiv === 'DD')?.shopSalesUnitPrice;
        shopSalesUnitPriceBlock[`unitPriceMM`] = data.closeNetAcmtlSalesList
            .find(item => item.acmtlSumDiv === 'MM')?.shopSalesUnitPrice;
        shopSalesUnitPriceBlock[`unitPriceYY`] = data.closeNetAcmtlSalesList
            .find(item => item.acmtlSumDiv === 'YY')?.shopSalesUnitPrice;
        tempData.push(feeSalesUnitPriceBlock);
        tempData.push(foodSalesUnitPriceBlock);
        tempData.push(shopSalesUnitPriceBlock);
        closeAcmtlSalesUnitList = tempData;
        tempData = [];

        data.closeAcmtlSalesList.map(item => {
          // const pureData = data.closeAcmtlSalesList
          //     .find(i => i.acmtlSumDiv === item.acmtlSumDiv);
          const pureNetData = data.closeNetAcmtlSalesList
              .find(i => i.acmtlSumDiv === item.acmtlSumDiv);
          // item.cartSalesAmt += pureData?.rentSalesAmt;
          item.totTaxAmt = pureNetData?.golfNetAmt;
          if (this.isAStyle) {
            if (item.acmtlSumDiv === 'DD') {
              item.foodSalesAmt = data?.consignmentSalesReportInfoV2?.ddFeeFoodNorAmt || 0;
              item.shopSalesAmt = data?.consignmentSalesReportInfoV2?.ddFeeShopNorAmt || 0;
            }
            if (item.acmtlSumDiv === 'MM') {
              item.foodSalesAmt = data?.consignmentSalesReportInfoV2?.mmFeeFoodNorAmt || 0;
              item.shopSalesAmt = data?.consignmentSalesReportInfoV2?.mmFeeShopNorAmt || 0;
            }
            if (item.acmtlSumDiv === 'YY') {
              item.foodSalesAmt = data?.consignmentSalesReportInfoV2?.yyFeeFoodNorAmt || 0;
              item.shopSalesAmt = data?.consignmentSalesReportInfoV2?.yyFeeShopNorAmt || 0;
            }
            item.totAmt = item.feeSalesAmt + item.cartSalesAmt + item.rentSalesAmt + item.foodSalesAmt +
                item.shopSalesAmt;
          }
          if (this.isBStyle) {
            item.cartSalesAmt = item.cartSalesAmt + item.rentSalesAmt;
          }
          item.netSalesAmt = item.totSalesAmt - item.totTaxAmt;
          return item;
        });

        if (this.isAStyle) {
          const ddFoodAmt = data?.consignmentSalesReportInfoV2?.ddFoodAmt || 0;
          const ddShopAmt = data?.consignmentSalesReportInfoV2?.ddShopAmt || 0;
          const mmFoodAmt = data?.consignmentSalesReportInfoV2?.mmFoodAmt || 0;
          const mmShopAmt = data?.consignmentSalesReportInfoV2?.mmShopAmt || 0;
          const yyFoodAmt = data?.consignmentSalesReportInfoV2?.yyFoodAmt || 0;
          const yyShopAmt = data?.consignmentSalesReportInfoV2?.yyShopAmt || 0;

          data.consignmentSalesReportInfoV2 = {
            ddTotAmt: ddFoodAmt + ddShopAmt,
            mmTotAmt: mmFoodAmt + mmShopAmt,
            yyTotAmt: yyFoodAmt + yyShopAmt,
            ddFoodAmt: ddFoodAmt || 0,
            mmFoodAmt: mmFoodAmt || 0,
            yyFoodAmt: yyFoodAmt || 0,
            ddShopAmt: ddShopAmt || 0,
            mmShopAmt: mmShopAmt || 0,
            yyShopAmt: yyShopAmt || 0,
          };

          closeAcmtlSalesUnitList.push({
            divName: '총 객단가',
            unitPriceDD: Math.round(
                data.closeAcmtlSalesList.find(item => item.acmtlSumDiv === 'DD').totSalesAmt
                / data.closePaymtList.find(item => item.paymtCode === 'DAY').visitTotPer,
            ),
            unitPriceMM: Math.round(
                data.closeAcmtlSalesList.find(item => item.acmtlSumDiv === 'MM').totSalesAmt
                / data.closePaymtList.find(item => item.paymtCode === 'MONTH').visitMonthPer,
            ),
            unitPriceYY: Math.round(
                data.closeAcmtlSalesList.find(item => item.acmtlSumDiv === 'YY').totSalesAmt
                / data.closePaymtList.find(item => item.paymtCode === 'YEAR').visitYearPer,
            ),
          });
        }
      } else if (this.isCStyle) {
        data.closeAcmtlSalesList.map(item => {
          item.netSalesAmt = item.totSalesAmt - item.totTaxAmt;
          return item;
        });
      }

      data.storeDailyCardReportList.forEach((item, index) => {
        const isLastData = index === data.storeDailyCardReportList.length - 1;
        const no = (index % 4) + 1;
        const block = {};
        block[`purcName${no}`] = item.purcName;
        block[`salesCnt${no}`] = item.salesCnt;
        block[`salesAmt${no}`] = item.salesAmt;
        tempData.push(block);
        if (tempData.length === 4 || isLastData) {
          const totBlock = {};
          if (isLastData) {
            totBlock['purcName4'] = '합계';
            totBlock['salesCnt4'] = data.storeDailyCardReportList.reduce(
                (acc, cur) => acc + cur.salesCnt,
                0,
            );
            totBlock['salesAmt4'] = data.storeDailyCardReportList.reduce(
                (acc, cur) => acc + cur.salesAmt,
                0,
            );
            if (tempData.length === 4) {
              storeDailyCardReportList.push(Object.assign(...tempData));
              tempData = [];
            }
            tempData.push(totBlock);
          }
          storeDailyCardReportList.push(Object.assign(...tempData));
          tempData = [];
        }
      });

      data.closePaymtList = data.closePaymtList.filter((data) => data.visitYearPer !== "0" || data.visitTotPer !== "0" || data.visitMonthPer !== "0" );


      const reportData = {
        reportJson: {
          jsonData: {...data, closeAcmtlSalesUnitList, storeDailyCardReportList},
          bsnDate: this.isAStyle || this.isBStyle
              ? moment(this.dailyReportSearchOptions.bsnDate).format('(YYYY년 MM월 DD일(ddd))')
              : this.dailyReportSearchOptions.bsnDate,
          month: moment(this.dailyReportSearchOptions.bsnDate).format(this.isAStyle ? 'M' : 'MM'),
          closeDiv: commonCodesGetComName(
              'CLOSE_DIV',
              this.closeDiv || commonCodeGetComCodeDefaultValue('CLOSE_DIV'),
          ),
          bizName: (bizInfo?.ccName || '') + ' (V.A.T 별도)',
          username: this.username,
          uniqueCode: this.$options.name,
          showWeatherFlag: this.dailyReportSearchOptions.showWeatherFlag,
          teamAvgPer: this.isAStyle
              ? Math.round(data.visitTeamCntAndPerTeamList
              ?.find(item => item.acmtlSumDiv === 'DD')
              ?.teamAvgPer) || 0
              : null,
          remarks: data.remarks,
        },
      };

      this.$refs.reportViewComponent.postReport(
          reportData,
          `${this.$options.name}${this.dailyReportSearchOptions.reportStyle}`,
      );
    },
  },
};
</script>
