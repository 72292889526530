import request from '@/utils/request';

/**
 * @return Promise
 */
export function getDailyReport(bsnDate, amtFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: '/daily_close_management/front-daily-report',
    method: 'get',
    params: {
      bsnDate,
      amtFlag,
    },
  });
}

export function putDailyReportRemarks(bsnDate, remarks) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/daily_close_management/front-daily-report/remarks/${bsnDate}`,
    method: 'put',
    data: {
      remarks,
    },
  });
}
